/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'
import { Close, MenuButton, Switch, Flex, Box, useColorMode } from 'theme-ui'
import { Link } from 'gatsby'

const Header: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [colorMode, setColorMode] = useColorMode()

  const isDark = colorMode === `dark`

  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }
  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen)

  return (
    <Box
      as="header"
      sx={{
        bg: 'transparent',
        color: 'heading',
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100vw',
        zIndex: '100',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MenuButton
          onClick={toggleMobileMenu}
          sx={{
            display: [
              mobileMenuOpen ? 'none' : 'block',
              mobileMenuOpen ? 'none' : 'block',
              'none',
            ],
            width: '60px',
            height: '60px',
            padding: 3,
            zIndex: '101',
            color: 'text',
          }}
        />
        <Close
          onClick={toggleMobileMenu}
          sx={{
            display: [
              mobileMenuOpen ? 'block' : 'none',
              mobileMenuOpen ? 'block' : 'none',
              ,
              'none',
            ],
            width: '60px',
            height: '60px',
            padding: 3,
            zIndex: '101',
            color: 'text',
          }}
        />
        <Box sx={{ zIndex: '101' }}>
          <Switch
            sx={{
              width: '40px',
              backgroundColor: 'text',
              'input:checked ~ &': {
                backgroundColor: 'text',
              },
              display: ['block', 'block', 'none'],
            }}
            onChange={toggleColorMode}
            checked={isDark}
          />
        </Box>
      </Flex>
      <Flex
        as="nav"
        sx={{
          flexDirection: ['column', 'column', 'row'],
          justifyContent: ['flex-start', 'flex-start', 'center'],
          alignItems: ['center'],
          padding: 3,
          display: [
            mobileMenuOpen ? 'flex' : 'none',
            mobileMenuOpen ? 'flex' : 'none',
            'flex',
          ],
          position: ['absolute', 'absolute', 'relative'],
          top: 0,
          width: ['100%', '100%', 'auto'],
          bg: ['transparent', 'transparent', 'transparent'],
          transition: 'top 0.3s ease-in-out',
        }}
      >
        <Link
          to="/"
          sx={{
            color: 'inherit',
            boxSizing: 'border-box',
            margin: 0,
            minWidth: 0,
            textDecoration: 'none',
            fontWeight: 700,
            display: 'inline-block',
            padding: '0.5rem',
          }}
        >
          OUR PRODUCTS
        </Link>
        <Link
          to="/our-business"
          sx={{
            color: 'inherit',
            boxSizing: 'border-box',
            margin: 0,
            minWidth: 0,
            textDecoration: 'none',
            fontWeight: 700,
            display: 'inline-block',
            padding: '0.5rem',
          }}
        >
          OUR BUSINESS
        </Link>
        <Link
          to="/about-us"
          sx={{
            color: 'inherit',
            boxSizing: 'border-box',
            margin: 0,
            minWidth: 0,
            textDecoration: 'none',
            fontWeight: 700,
            display: 'inline-block',
            padding: '0.5rem',
          }}
        >
          ABOUT US
        </Link>
        <Link
          to="/recruitment"
          sx={{
            color: 'inherit',
            boxSizing: 'border-box',
            margin: 0,
            minWidth: 0,
            textDecoration: 'none',
            fontWeight: 700,
            display: 'inline-block',
            padding: '0.5rem',
          }}
        >
          RECRUIT
        </Link>
        <Box>
          <Switch
            sx={{
              width: '40px',
              backgroundColor: 'text',
              'input:checked ~ &': {
                backgroundColor: 'text',
              },
              display: ['none', 'none', 'block'],
            }}
            onChange={toggleColorMode}
            checked={isDark}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default Header
