/** @jsx jsx */
import { Box, jsx } from 'theme-ui'

const Footer = () => {
  return (
    <Box as="footer" variant="footer" sx={{ width: '100%', margin: '0 auto' }}>
      &copy; {new Date().getFullYear()} UKMETA. All rights reserved.
    </Box>
  )
}

export default Footer
